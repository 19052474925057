import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import Helmet from "react-helmet";
import RollStyles from "./Roll.module.scss";
import arrowRight from "../img/arrow_right.svg";

export default class BlogRoll extends React.Component {
  render() {
    const { data, title } = this.props;
    const { edges: posts } = data.allMarkdownRemark;

    return (
      <div className={RollStyles.wrapper}>
        <Helmet titleTemplate="%s | Pizza for Breakfast">
          <title>{`${title}`}</title>
          {/* <meta
              name="description"
              content={`${post.frontmatter.description}`}
            /> */}
        </Helmet>
        <h1 className={RollStyles.h1}>{title}</h1>
        {posts &&
          posts.map(({ node: post }) => (
            <Link
              key={post.id}
              to={post.fields.slug}
              className={RollStyles.linkWrapper}
            >
              <div className={RollStyles.itemWrapper}>
                {post.frontmatter.thumbnail && (
                  <img
                  src={post.frontmatter.thumbnail}
                  />
                )}
                <div className={RollStyles.rightSide}>
                  <h2 className={RollStyles.title}>{post.frontmatter.title}</h2>
                  <span className={RollStyles.date}>
                    {post.frontmatter.date}
                  </span>
                  <div className={RollStyles.checkItOutWrapper}>
                    <p className={RollStyles.checkItOut}>check it out</p>
                    <img src={arrowRight} alt="check it out" />
                  </div>
                </div>
              </div>
            </Link>
          ))}
      </div>
    );
  }
}

BlogRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array
    })
  })
};
